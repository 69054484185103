<ul class="menu-list" *transloco="let t">
  @let user = user$ | async;
  @if (!user?.pro) {
    <li>
      <cft-tab-pill [link]="craftsmenLink" class="menu-list__item">
        <fa-icon [icon]="craftsmenIcon"></fa-icon>
        <span class="hidden lg:inline">{{ t('navbar.craftsmen.title') }}</span>
        <cft-tooltip class="menu-list__item__tooltip">{{ t('navbar.craftsmen.title') }}</cft-tooltip>
      </cft-tab-pill>
    </li>
    <li>
      <cft-tab-pill class="menu-list__item" [link]="servicesLink">
        <fa-icon [icon]="servicesIcon"></fa-icon>
        <span class="menu-list__item__title">{{ t('navbar.services') }}</span>
        <cft-tooltip class="menu-list__item__tooltip">{{ t('navbar.services') }}</cft-tooltip>
      </cft-tab-pill>
    </li>
  }
  <li>
    <cft-tab-pill class="menu-list__item" [link]="articlesLink">
      <fa-icon [icon]="articlesIcon"></fa-icon>
      <span class="menu-list__item__title">{{ t('navbar.articles') }}</span>
      <cft-tooltip class="menu-list__item__tooltip">{{ t('navbar.articles') }}</cft-tooltip>
    </cft-tab-pill>
  </li>
</ul>
